<!-- 收藏组件 -->
<template>
	<div class="collect" v-if="userLogin" :class="[value == 1 ? 'active' : '', ab ? 'ab' : '']" @click.stop="handleCollect">
		<i :class="value == 1 ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
		{{ value == 1 ? "已收藏" : "收藏" }}
	</div>
</template>
<script>
export default {
	name: "collect",
	props: {
		ab: {
			type: Boolean,
			default: true,
		},
		value: {
			type: Number,
			default: 0,
		},
		itemId: {
			type: [String, Number],
		},
		type: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			collectionTypeList: {
				拟建信息: 60611007,
				招标信息: 60611009,
				中标信息: 60611008,
				政策规划: 60611004,
				行业动态: 60611002,
				分析报告: 60611001,
				土地交易: 60611010,
				会员专区: 60611011,
			},
		};
	},
	computed: {
		userLogin() {
			return !!this.$session.getUsers();
		},
	},
	methods: {
		async handleCollect() {
			let _this = this;
			let res = null;
			let obj = {
				relationId: this.itemId,
				collectionType: this.collectionTypeList[this.type],
			};
			if (this.value == 1) {
				//取消
				this.$confirm("您确认要取消这个收藏吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						const loading = _this.$loading({
							lock: true,
							text: "加载中...",
							spinner: "el-icon-loading",
							background: "rgba(255, 255, 255, 0.3)",
							fullscreen: false,
						});
						this.$api.personalCenter.cancel(obj).then(row => {
							loading.close();
							this.cancelCollectTip(row);
						});
					})
					.catch(error => {
						console.log("error", error);
					});
			} else {
				//收藏
				const loading = _this.$loading({
					lock: true,
					text: "加载中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				res = await this.$api.personalCenter.collection(obj);
				this.cancelCollectTip(res);
				loading.close();
			}
		},
		cancelCollectTip(res) {
			if (res.code == 200) {
				this.$emit("input", this.value == 1 ? 0 : 1);
				this.$message({
					message: this.value == 1 ? "已取消" : "已收藏",
					type: "success",
				});
			} else {
				this.$message({
					message: res.msg,
					type: "warning",
				});
			}
		},
	},
};
</script>
<style scoped>
.collect {
	width: max-content !important;
	right: 0;
	top: 0px;
	font-size: 14px;
	color: #999;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	min-width: 70px;
	text-align: center;
	background-color: aliceblue;
	padding: 10px 20px;
	border-radius: 20px;
}
.ab {
	position: absolute;
}
.collect i {
	font-size: 18px;
	margin-right: 3px;
	margin-top: -1px;
}

.active {
	color: #1181fa;
}
</style>
