<template>
  <div class="member-modal" v-show="open">
    <div class="modal-img animated fadeInDownBig">
      <div class="close-icon" @click="close"></div>
      <div class="title">开通会员，立享使用特权</div>
      <div class="content">
        <span>您暂未开通会员，现在开通立享</span>
        <span class="type"
          >【中标查询】、【中标统计数据】、【政策规划】、【资料报告】</span
        >
        <span>等数据库使用权限。</span>
      </div>
      <div class="icon-box">
        <div class="icon-item">
          <div class="icon"></div>
          <div>行业动态</div>
        </div>
        <div class="icon-item">
          <div class="icon two"></div>
          <div>政策规划</div>
        </div>
        <div class="icon-item">
          <div class="icon three"></div>
          <div>经济数据</div>
        </div>
        <div class="icon-item">
          <div class="icon four"></div>
          <div>资料报告</div>
        </div>
      </div>
      <el-button type="primary" @click="toMember">立即开通</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  // watch: {
  //   // 监听弹窗控制滚动条显示与隐藏
  //   open(newVal) {
  //     if (newVal) {
  //       window.document.body.style.overflowY = "hidden";
  //     } else {
  //       window.document.body.style.overflowY = "scroll";
  //     }
  //   },
  // },
  methods: {
    close() {
      this.open = false;
    },
    toMember() {
      window.open(`/member`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
