<template>
	<div class="bidding-information">
		<div class="top-img">
			<div class="top-name" v-if="!subscribeId">拟建信息库</div>
			<div class="search-input">
				<el-input v-model.trim="conPO.search" placeholder="请输入项目名称等关键词" maxlength="100"
					@keyup.enter.native="handleQuery"></el-input>
				<span class="search-btn" @click="handleQuery">查询</span>
			</div>
		</div>
		<div class="title-card">
			<div class="title-box">
				<div class="title">拟建信息库</div>
				<div class="result">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>
			</div>
			<div class="condition-box">
				<div class="condition-item">
					<div class="title">省份</div>
					<div class="item-box">
						<div class="all-btn" :class="isProAll ? 'all-btn-select' : ''" @click="proAll">全部</div>
						<div class="name-box" :class="provincesStatus ? 'name-open' : ''">
							<div class="name" :class="proSelect.indexOf(item) !== -1 ? 'name-select' : ''"
								v-for="item in regionList" :key="item.code" @click="selectPro(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldProvinces">
						<span>{{ provincesStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="provincesStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item" v-show="cityData.length != 0">
					<div class="title">地级市</div>
					<div class="item-box">
						<div class="name-box" :class="cityStatus ? 'name-open' : ''">
							<div class="name" :class="citySelect.indexOf(item) !== -1 ? 'name-select' : ''"
								v-for="item in cityData" :key="item.code" @click="selectCity(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldCity">
						<span>{{ cityStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="cityStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item">
					<div class="title" style="margin-top: 10px">时间</div>
					<div class="year-box">
						<div class="all-btn" :class="isYearAll ? 'all-btn-select' : ''" @click="yearAll">不限</div>
						<div class="name-box">
							<div class="name" :class="yearSelect.indexOf(item.code) !== -1 ? 'name-select' : ''"
								v-for="item in yearData" :key="item.code" @click="selectYear(item)">
								{{ item.name }}
							</div>
						</div>
						<div class="custom-box">
							<div class="custom">自定义</div>
							<div>
								<el-date-picker v-model="startYear" type="month" :picker-options="startTime"
									:default-value="maxDate" placeholder="开始时间" value-format="yyyy-MM"
									@change="changeYear"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="endYear" type="month" :picker-options="endTime"
									:default-value="maxDate" placeholder="结束时间" value-format="yyyy-MM"
									@change="changeYear"></el-date-picker>
							</div>
						</div>
					</div>
				</div>
				<div class="condition-item">
					<div class="title" style="margin-top: 10px">投资额</div>
					<div class="year-box">
						<div class="all-btn" :class="isInvestmentAll ? 'all-btn-select' : ''" @click="investmentAll">不限
						</div>
						<div class="name-box">
							<div class="name" :class="conPO.investmentIndex === index ? 'name-select' : ''"
								v-for="(item, index) in investmentList" :key="item.name"
								@click="selectInvestment(item, index)">
								{{ item.name }}
							</div>
						</div>
						<div class="custom-box">
							<div class="custom">自定义</div>
							<div>
								<el-input v-model="conPO.customInvestmentStart" clearable type="number"
									style="width: 120px;" placeholder="最小投资额" @change="changeCustomInvestment" />
								万
							</div>
							<div class="to">至</div>
							<div>
								<el-input v-model="conPO.customInvestmentEnd" clearable type="number" style="width: 120px;"
									placeholder="最大投资额" @change="changeCustomInvestment" />
								万
							</div>
						</div>
					</div>
				</div>
				<div class="condition-item" style="margin-top: -10px">
					<div class="title" style="margin-top: 10px">项目类型</div>
					<div class="year-box">
						<div class="name-box" v-for="item in typeList" :key="item.dictValue">
							<div class="name" :class="selectedTypeData.indexOf(item) !== -1 ? 'name-select' : ''"
								@click="selectType(item)">
								{{ item.dictLabel }}
							</div>
						</div>
					</div>
				</div>
				<div class="condition-item" style="margin-top: -10px">
					<div class="title" style="margin-top: 10px">项目阶段</div>
					<div class="year-box">
						<div class="name-box" v-for="item in stageList" :key="item.dictValue">
							<div class="name" :class="selectedStageData.indexOf(item) !== -1 ? 'name-select' : ''"
								@click="selectState(item)">
								{{ item.dictLabel }}
							</div>
						</div>
					</div>
				</div>

				<div class="condition-item" style="margin-top: -10px" v-if="selectedStageData.findIndex(item => {
					return item.dictValue == '61001011';
				}) !== -1
					">
					<div class="title" style="margin-top: 10px">立项审批项目</div>
					<div class="year-box">
						<div class="name-box" v-for="item in childCodeList" :key="item.dictValue">
							<div class="name" :class="selectedStageData.indexOf(item) !== -1 ? 'name-select' : ''"
								@click="selectState(item)">
								{{ item.dictLabel }}
							</div>
						</div>
					</div>
				</div>
				<div class="condition-item" v-show="(selectedProData.length != 0 || selectedCityData.length != 0 || selectedYearData.length != 0 || selectedTypeData.length != 0 || selectedStageData.length != 0 || conPO.investmentIndex || conPO.investmentIndex === 0) &&
					!(
						selectedStageData.length == 1 &&
						selectedStageData.findIndex(item => {
							return item.dictValue == '61001011';
						}) == 0
					)
					">
					<div class="title">已选条件</div>
					<div class="selected-box">
						<div class="name" v-for="(item, i) in selectedProData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 1)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedCityData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 2)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedYearData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 3)"></div>
						</div>
						<div class="name" v-if="conPO.investmentIndex || conPO.investmentIndex === 0">
							<div>{{ investmentList[conPO.investmentIndex].name }}</div>
							<div class="clear-icon" @click="delSelectData('', '', 6)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedTypeData" :key="item.dictValue">
							<div>{{ item.dictLabel }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 4)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedStageData" :key="item.dictValue"
							v-show="item.dictValue != '61001011'">
							<div>{{ item.dictLabel }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 5)"></div>
						</div>
					</div>
					<div class="empty-btn" @click="emptySelect">清空筛选条件</div>
				</div>
			</div>
		</div>
		<div class="list-box">
			<div class="empty-box" v-show="total == 0">
				<empty :name="'暂无数据'"></empty>
			</div>
			<div class="list-item" v-for="(item, index) in pageBidding" :key="index" @click="toDetail(item)">
				<div class="name-top flex items-center justify-between">
					<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
						<div v-html="item.projectNameHighLight || item.projectName" class="text-ellipsis"></div>
					</el-tooltip>
					<collect v-model="item.isCollection" :ab="false" :itemId="item.proposedProjectId" type="拟建信息" />
				</div>
				<div class="bottom-box">
					<div class="tag-bottom">
						<div class="tag-item">项目阶段：{{ (item.stageCode == "立项审批项目" ? item.childStageCode : item.stageCode) ||
							"-" }}</div>
						<div class="tag-item">地址：{{ item.city || item.province }}</div>
						<div class="tag-item">投资额：{{ item.investmentAmount | moneyFormat }}</div>
					</div>
					<div class="date">发布时间：{{ item.publishedDate }}</div>
				</div>
				<div class="bottom-content-box" v-show="item.contentsHighLight">
					<span class="contentTitle">内容：</span>
					<span v-html="item.contentsHighLight"></span>
				</div>
			</div>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0" :key="paginationKey">
			</pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :text="text"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import Empty from "@/components/Empty";
import MemberModal from "@/components/MemberModal";
import ToPayOrMember from "@/components/ToPayOrMember";
import { doEncrypt, doDecryptStr } from "@/utils/smCrypto.js";
import collect from "@/components/collect";
import { mapGetters } from "vuex";
export default {
	dicts: ["6068"],
	components: {
		Pagination,
		LoginModal,
		Empty,
		MemberModal,
		ToPayOrMember,
		collect,
	},
	props: {
		subscribeId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			cityData: [], // 市级数据
			provincesStatus: false, // 省份展开收起状态
			cityStatus: false, // 地级市展开收起状态
			proSelect: [], // 选中的省份
			isProAll: true, // 省份是否全选
			citySelect: [], // 选中的地级市
			isYearAll: true, // 年份是否全选
			yearSelect: [], // 选中的年份
			projectState: false,
			yearData: [
				{
					name: "今天",
					code: "1",
				},
				{
					name: "近3天",
					code: "3",
				},
				{
					name: "近7天",
					code: "7",
				},
				{
					name: "近30天",
					code: "30",
				},
				{
					name: "近60天",
					code: "60",
				},
				{
					name: "近90天",
					code: "90",
				},
				{
					name: "近180天",
					code: "180",
				},
				{
					name: "近一年",
					code: "365",
				},
			],
			// typeList: [
			//   "房屋建筑",
			//   "市政",
			//   "公路",
			//   "生态环保",
			//   "水利水电",
			//   "能源电力",
			//   "城市轨道交通",
			//   "工程勘察设计咨询",
			//   "乡村建设",
			//   "信息电子",
			//   "铁路",
			//   "机场",
			//   "港口航道",
			//   "其他",
			// ],
			investmentList: [
				{
					value: ["", 10000],
					name: "小于1亿元",
				},
				{
					value: [10000, ""],
					name: "1亿元以上",
				},
				{
					value: [50000, ""],
					name: "5亿元以上",
				},
				{
					value: [100000, ""],
					name: "10亿元以上",
				},
				{
					value: [-1, ""],
					name: "其他",
				},
			],
			isInvestmentAll: true,
			typeList: [],
			stageList: [],
			childCodeList: [],
			selectedProData: [], // 已选的省级数据
			selectedCityData: [], // 已选地级市数据
			selectedYearData: [], // 已选地年份数据
			selectedTypeData: [],
			selectedStageData: [],
			startYear: "",
			endYear: "",
			pageReport: [],
			conPO: {
				platform: 1,
				investmentIndex: "",
				investmentStart: "",
				investmentEnd: "",
				customInvestmentStart: "",
				customInvestmentEnd: "",
				search: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
				dateOrder: false,
			},
			total: 0,
			pageBidding: [],
			paginationKey: "",
			text: "",
			minDate: "",
			maxDate: "",
		};
	},
	computed: {
		startTime() {
			return {
				disabledDate: time => {
					let disableTime;
					if (this.endYear) {
						disableTime = time.getTime() > new Date(this.endYear).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						console.log("this.maxDate", this.maxDate);
						disableTime = time > this.maxDate || time < this.minDate;
					}

					return disableTime;
				},
			};
		},
		endTime() {
			return {
				disabledDate: time => {
					if (this.startYear) {
						return time.getTime() < new Date(this.startYear).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
		...mapGetters(["regionList"]),
	},
	filters: {
		moneyFormat(money) {
			return money ? (money / 10000 + "万") : "-";
		},
	},
	mounted() {
		this.getTimeSelector();
		this.getBidProjectType();
		this.getTenderInfo();
		this.getProjectStage();
	},
	methods: {
		getTimeSelector() {
			this.$api.industry.getTimeSelector().then(res => {
				this.minDate = new Date(res.data.minDateTime + " 00:00:00");
				this.maxDate = new Date(res.data.maxDateTime + ` 23:59:59`);
			});
		},
		// 获取项目类型
		getBidProjectType() {
			this.$api.industry
				.getProjectType()
				.then(res => {
					this.typeList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		//获取项目阶段
		getProjectStage() {
			this.$api.industry
				.getProjectStage()
				.then(res => {
					this.stageList = res.data;
					let otherItem = {};
					let otherChildItem = {};
					this.stageList.map((item, index) => {
						if (item.dictValue == 61001011) {
							this.childCodeList = item.childCodeList;
							this.childCodeList.map((ite,i)=>{
								if(ite.dictValue==61001007){
									this.childCodeList.splice(i, 1);
									otherChildItem=ite
								}
							})
							this.childCodeList.push(otherChildItem)
						}
						if (item.dictValue == 61001015) {
							this.stageList.splice(index, 1);
							otherItem = item;
						}
					});
					this.stageList.push(otherItem);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 拟建项目
		selectState(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.projectState = true;
				let { selectedStageData } = this;
				let valueIndexStage = selectedStageData.indexOf(item);
				if (valueIndexStage == -1) {
					if (item.childCodeList && item.childCodeList.length > 0) {
						item.childCodeList.map(element => {
							selectedStageData.push(element);
						});
					}
					// 选择
					selectedStageData.push(item);
					this.selectedStageData = selectedStageData;
					this.handleQuery();
				} else {
					// 取消选择
					if (item.childCodeList && item.childCodeList.length > 0) {
						let childCodeListAll = [];
						selectedStageData.map(element => {
							if (element.childCodeList && element.childCodeList.length > 0) {
								element.childCodeList.map(aElement => {
									childCodeListAll.push(aElement);
								});
							}
						});
						this.selectedStageData = this.removeDuplicates(childCodeListAll, selectedStageData);
						this.selectedStageData = this.selectedStageData.filter(item => item.dictValue != "61001011");
					} else {
						selectedStageData.splice(valueIndexStage, 1);
					}
					this.handleQuery();
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 数组去重
		removeDuplicates(arr1, arr2) {
			const uniqueArr = arr2.filter((item, index) => !arr1.includes(item));
			return uniqueArr;
		},
		// 点击搜索
		handleQuery() {
			if (this.$session.getUsers()) {
				this.$refs.pagination.page = 1;
				this.conPO.pageNum = 1;
				this.conPO.pageSize = 10;
				this.getTenderInfo('flippingPages');
			} else {
				this.$refs.loginModal.visible = true;
			}
		},

		// 将时间戳转换成日期格式：
		timestampToTime(timestamp) {
			// 时间戳为10位需*1000，时间戳为13位不需乘1000
			var date = new Date(timestamp * 1000);
			var Y = date.getFullYear() + "-";
			var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
			var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			var h = date.getHours() + ":";
			var m = date.getMinutes() + ":";
			var s = date.getSeconds();
			return Y + M + D;
		},

		// 获取拟建项目库列表
		getTenderInfo(flippingPages) {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let { conPO, proSelect, citySelect, yearSelect, startYear, endYear, selectedTypeData, selectedStageData } = this;
			let provinces = [];
			proSelect.map(item => {
				provinces.push(item.code);
			});
			conPO.provinceCodes = provinces.toString(); 

			let citys = [];
			citySelect.map(item => {
				citys.push(item.code);
			});
			conPO.cityCodes = citys.toString();
			conPO.dayCount = yearSelect.toString();
			conPO.startDate = startYear;
			conPO.endDate = endYear;
			// 项目类型筛选数据
			let projectTypes = [];
			selectedTypeData.map(item => {
				projectTypes.push(item.dictLabel);
			});
			conPO.projectType = projectTypes.toString();

			// 项目阶段筛选数据
			let projectStates = [];
			// 过滤与立项项目审批相同的筛选数据
			selectedStageData = this.selectedStageData.filter(item => {
				return this.childCodeList.indexOf(item) === -1;
			});
			selectedStageData.map(item => {
				if (item.dictValue != "61001011") {
					projectStates.push(item.dictValue);
				}
			});
			conPO.stageCode = projectStates.toString();

			// 立项项目审批筛选条件
			let childStageCodes = [];
			this.selectedStageData.forEach(val => {
				this.childCodeList.forEach(element => {
					if (val.dictValue.indexOf(element.dictValue) > -1) {
						childStageCodes.push(val);
						return;
					}
				});
			});
			let childStageCodeLists = [];
			childStageCodes.map(item => {
				childStageCodeLists.push(item.dictValue);
			});
			conPO.childStageCode = childStageCodeLists.toString();

			if (conPO.customInvestmentEnd || conPO.customInvestmentStart) {
				conPO.investmentEnd = conPO.customInvestmentEnd;
				conPO.investmentStart = conPO.customInvestmentStart;
			}
			if (flippingPages !== "flippingPages") {
				conPO.investmentStart = conPO.investmentStart ? (conPO.investmentStart == -1 ? -1 : conPO.investmentStart * 10000) : "";
				conPO.investmentEnd = conPO.investmentEnd ? conPO.investmentEnd * 10000 : "";
			}

			let func = this.$api.industry.getProposedProjectPage(conPO)
			if (this.subscribeId) {
				conPO.subscribeId = this.subscribeId
				func = this.$api.industry.getSubscribeProposedProjectPage(conPO)
			}
			func.then(res => {
				loading.close();
				this.total = res.total;
				this.pageBidding = res.rows;
				let contentsHighLightReg = /[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]+|<em>[^<]*<\/em>/g;
				res.rows.forEach(item => {
					item.publishedDate = item.publishedDate.toString().slice(0, 10);
					item.contentsHighLight = item.contentsHighLight.match(contentsHighLightReg).join("");
				});
				this.pageBidding = res.rows;

				console.log("res.rows", res.rows);
			})
				.catch(msg => {
					loading.close();
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 请求市区城
		getCity(item) {
			let obj = {
				parentRegion: item.regionId,
				isLazyLoad: 10011001,
				state: 10021001,
				hasChildren: true
			}
			this.$api.system.regionList(obj).then(res => {
				if (res.data.length > 0) {
					res.data.map(item => {
						item.name = item.regionFullname
						item.code = item.unionCode
					})
					let data = res.data;
					this.cityData = [...data];
				}
			});
		},
		// 展开收起省份
		foldProvinces() {
			this.provincesStatus = !this.provincesStatus;
		},
		// 展开收起地级市
		foldCity() {
			this.cityStatus = !this.cityStatus;
		},
		// 单选省份
		selectPro(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let { proSelect, cityData, selectedProData, citySelect, selectedCityData } = this;
				let valueIndex = proSelect.indexOf(item);
				if (valueIndex == -1) {
					this.proSelect.push(item);
					this.selectedProData.push(item)
					if (this.proSelect.length == 0 && this.citySelect.length == 0) {
						this.isProAll = true;
					} else {
						this.isProAll = false;
					}
					// 判断市级数据中是否已经存在当前省的市级数据
					if (citySelect.some(key => key.code.slice(0, 2) == item.code.slice(0, 2))) {
						// 处理市级数据（不需要当前省级的市级数据）
						let newCitySelect = [];
						citySelect.forEach(key => {
							if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
								newCitySelect.push(key);
							}
						});
						this.citySelect = newCitySelect;
						// 处理已选条件的市级数据（不需要当前省级的市级数据）
						let newSelectedCityData = [];
						selectedCityData.forEach(key => {
							if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
								newSelectedCityData.push(key);
							}
						});
						this.selectedCityData = newSelectedCityData; 
					}
					this.handleQuery();
					// 选择
					this.getCity(item);
				} else {
					// 取消选择
					proSelect.splice(valueIndex, 1);
					// 删除对应的市级数据
					let cityResult = [];
					cityData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							cityResult.push(key);
						}
					});
					this.cityData = cityResult;
					// （删除已选的省级数据）比较 item和selectedProData取出不同的值
					let itemArr = [item];
					let selectedCodes = [];
					itemArr.forEach(({ name }) => {
						selectedCodes.push(name);
					});
					let selectedResult = selectedProData.filter(({ name }) => !selectedCodes.includes(name));
					this.selectedProData = selectedResult;
					this.handleQuery();
				}
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 全选省份
		proAll() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					return;
				}
				this.isProAll = !this.isProAll;
				if (this.isProAll) {
					this.selectedProData = [];
					this.proSelect = [];
					this.cityData = [];
					this.selectedCityData = [];
					this.citySelect = [];
				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 单选地级市
		selectCity(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let { citySelect, selectedCityData, proSelect, selectedProData } = this;
				let valueIndex = citySelect.indexOf(item);
				if (valueIndex == -1) {
					// 选择
					citySelect.push(item);
					this.selectedCityData = [...selectedCityData, ...[item]];
					// 处理省级数据（不需要当前市级的省级数据）
					let newCitySelect = [];
					proSelect.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newCitySelect.push(key);
						}
					});
					this.proSelect = newCitySelect;
					// 处理已选条件的省级数据（不需要当前市级的省级数据）
					let newSelectedProData = [];
					selectedProData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newSelectedProData.push(key);
						}
					});
					this.selectedProData = newSelectedProData;
					if (this.proSelect.length == 0) {
						this.isProAll = false;
					}
					this.handleQuery();
				} else {
					// 取消选择
					citySelect.splice(valueIndex, 1);
					// 比较 item和selectedCityData取出不同的值
					let itemArr = [item];
					let selectedCodes = [];
					itemArr.forEach(({ code }) => {
						selectedCodes.push(code);
					});
					let selectedResult = selectedCityData.filter(({ code }) => !selectedCodes.includes(code));

					if (selectedResult.length == 0) {
						// 无选中的市时
						this.isProAll = true;
					}
					this.selectedCityData = selectedResult;
					this.handleQuery();
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 全选年份
		yearAll() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (this.yearSelect.length == 0 && this.startYear == "" && this.endYear == "") {
					return;
				}
				this.isYearAll = !this.isYearAll;
				if (this.isYearAll) {
					this.startYear = "";
					this.endYear = "";
					this.selectedYearData = [];
					this.yearSelect = [];
				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 单选年份
		selectYear(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let { yearSelect } = this;
				let valueIndex = yearSelect.indexOf(item.code);
				if (valueIndex == -1) {
					// 选择
					this.yearSelect = [item.code];
					this.selectedYearData = [item];
					this.startYear = "";
					this.endYear = "";
					this.handleQuery();
				} else {
					// 取消选择
					this.yearSelect = [];
					this.selectedYearData = [];
					this.handleQuery();
				}
				if (this.yearSelect.length == 0) {
					this.isYearAll = true;
				} else {
					this.isYearAll = false;
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		investmentAll() {
			this.isInvestmentAll = true;
			this.conPO.investmentIndex = "";
			this.conPO.investmentStart = "";
			this.conPO.investmentEnd = "";
			this.conPO.customInvestmentStart = "";
			this.conPO.customInvestmentEnd = "";
			this.handleQuery();
		},
		selectInvestment(e, index) {
			if (this.conPO.investmentIndex === index) {
				this.isInvestmentAll = true;
				this.conPO.investmentIndex = "";
				this.conPO.investmentStart = "";
				this.conPO.investmentEnd = "";
			} else {
				this.isInvestmentAll = false;
				this.conPO.investmentIndex = index;
				this.conPO.investmentStart = e.value[0];
				this.conPO.investmentEnd = e.value[1];
			}

			this.getTenderInfo();
		},
		changeCustomInvestment(e, index) {
			if (e) {
				this.isInvestmentAll = false;
				this.conPO.investmentIndex = "";
				this.conPO.investmentStart = "";
				this.conPO.investmentEnd = "";
				this.handleQuery();
			}
		},
		// 改变时间
		changeYear() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.isYearAll = false;
				this.selectedYearData = [];
				this.yearSelect = [];
				if ((this.startYear == "" || this.startYear == null) && (this.endYear == "" || this.endYear == null)) {
					this.isYearAll = true;
				}
				this.handleQuery();
			} else {
				this.startYear = null;
				this.endYear = null;
				this.$refs.loginModal.visible = true;
			}
		},
		// 删除单条所选内容
		delSelectData(item, index, type) {
			if (type == 1) {
				// 删除省
				let { proSelect, citySelect, cityData } = this;
				this.selectedProData.splice(index, 1);
				// 删除对应的市级数据
				let cityResult = [];
				cityData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						cityResult.push(key);
					}
				});
				this.cityData = cityResult;
				let newData = proSelect.filter(key => ![item.name].includes(key.name));
				// if (newData.length == 0 && this.selectedCityData.length == 0) {
				//   // 无选中的省，市
				//   this.isProAll = true;
				//   this.selectedProData = [];
				//   this.proSelect = [];
				//   this.cityData = [];
				//   this.selectedCityData = [];
				//   this.citySelect = [];
				// }
				this.proSelect = newData;
				if (proSelect.length == 1 && citySelect.length == 0) {
					this.cityData = [];
				}
				this.getTenderInfo();
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else if (type == 2) {
				// 删除市
				let { citySelect, selectedProData } = this;
				this.selectedCityData.splice(index, 1);
				let newData = citySelect.filter(key => ![item.name].includes(key.name));
				if (newData.length == 0) {
					this.selectedCityData = [];
					this.citySelect = [];
				}
				if (newData.length == 0 && this.proSelect.length == 0) {
					this.isProAll = true;
				}
				this.citySelect = newData;
				if (citySelect.length == 1 && selectedProData.length == 0) {
					this.cityData = [];
				}
				this.handleQuery();
			} else if (type == 3) {
				// 删除年份
				this.isYearAll = true;
				this.selectedYearData = [];
				this.yearSelect = [];
				this.handleQuery();
			} else if (type == 4) {
				this.selectedTypeData.splice(index, 1);
				this.handleQuery();
			} else if (type == 5) {
				// this.projectState = false;
				this.selectedStageData.splice(index, 1);
				this.handleQuery();
			} else if (type == 5) {
			} else if (type == 6) {
				this.isInvestmentAll = true;
				this.conPO.investmentIndex = "";
				this.conPO.investmentStart = "";
				this.conPO.investmentEnd = "";
				this.handleQuery();
			}
		},
		// 点击类型
		selectType(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let { selectedTypeData } = this;
				let valueIndex = selectedTypeData.indexOf(item);
				if (valueIndex == -1) {
					// 选择
					selectedTypeData.push(item);
					this.selectedTypeData = selectedTypeData;
					this.handleQuery();
				} else {
					// 取消选择
					selectedTypeData.splice(valueIndex, 1);
					this.selectedTypeData = selectedTypeData;
					this.handleQuery();
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 清空筛选条件
		emptySelect() {
			this.cityData = [];
			this.proSelect = [];
			this.isProAll = true;
			this.citySelect = [];
			this.isYearAll = true;
			this.yearSelect = [];
			this.selectedProData = [];
			this.selectedCityData = [];
			this.selectedYearData = [];
			this.startYear = "";
			this.endYear = "";
			this.selectedTypeData = [];
			this.selectedStageData = [];
			this.projectState = false;
			this.conPO.investmentIndex = "";
			this.conPO.investmentStart = "";
			this.conPO.investmentEnd = "";
			this.handleQuery();
		},
		// 点击列表查看详情
		toDetail(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.getTenderInfoDetail(item.proposedProjectId);
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		getTenderInfoDetail(id) {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let params = {
				moduleCode: 1006,
				childModuleCode: 10061001,
				type: 2,
			};
			this.$api.industry
				.getMemberPermission(params)
				.then(res => {
					console.log("res", res);
					if (res.data != 0) {
						const routeUrl = this.$router.resolve({
							path: `/industry/proposed-detail?id=${id}`,
						});
						window.open(routeUrl.href, "_blank");
					} else {
						this.text = "您今日免费次数已使用完，请开通权限后继续使用";
						this.$refs.toPayOrMember.openDialog();
					}
				})
				// .catch(msg => {
				// 	console.log("msg", msg);
				// 	if (msg.code == "10001111") {
				// 		this.$refs.toPayOrMember.openDialog();
				// 		this.text = msg?.msg;
				// 	} else {
				// 		this.$message.error(msg?.msg);
				// 	}
				// })
				.finally(() => {
					loading.close();
				});
		},
		// 分页方法
		paginChange(page, pageSize) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.conPO.pageNum = page;
				this.conPO.pageSize = pageSize;
				this.getTenderInfo("flippingPages");
			} else {
				this.paginationKey = new Date().getTime();
				this.$refs.loginModal.visible = true;
			}
		},
	},
};
</script>

<style lang="less">@import "./index.less";</style>
