<template>
  <div>
    <div class="login-modal">
      <Modal :visible.sync="visible" @reset="resetFields">
        <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
          {{ bidding ? "绑定账号" : "登录" }}
        </div>
        <div class="login-form">
          <div class="login-msg">{{ bidding ? "您还未绑定账号，请先绑定~" : "您还未登录，请先登录~" }}</div>
          <div class="form-container">
            <div class="form-box">
              <el-form ref="dataPO" :model="dataPO" :rules="rules">
                <el-form-item prop="phone">
                  <el-input
                    v-model="dataPO.phone"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    @blur="dataPO.phone = $event.target.value"
                    placeholder="您的手机号"
                    prefix-icon="iconfont icon-shouji"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <div class="code-form-box">
                    <el-input
                      class="code-form"
                      v-model="dataPO.code"
                      placeholder="请输入验证码"
                      prefix-icon="iconfont icon-suo"
                    ></el-input>
                    <div class="get-code" @click.prevent="getCode">{{ codeText }}</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="agree-box">
              <el-checkbox v-model="agreeChecked"> </el-checkbox>
              <span class="content">我已阅读并同意</span>
              <span class="agree" @click="openAgree">《用户注册协议》</span>
            </div>
            <div class="submit-form" @click.prevent="submitForm">{{ bidding ? "立即绑定" : "立即登录" }}</div>
          </div>
        </div>
      </Modal>
    </div>
    <agreement-modal
      ref="agreementModal"
      :title="'用户注册协议'"
      :text="false"
    ></agreement-modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import AgreementModal from "@/components/agreementModal";
import { doEncrypt,doDecryptStr } from "@/utils/smCrypto.js";
export default {
  components: {
    Modal,
    AgreementModal,
  },
  props: ['bidding','state'],
  data() {
    let checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg =
          "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
        if (new RegExp(reg).test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      visible: false,
      dataPO: {
        phone: "", //手机号
        code: "", // 验证码
        terminal: 1, // pc端
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      codeText: "获取验证码",
      agreeChecked: false, //同意协议状态
    };
  },
  methods: {
    // 获取验证码
    getCode() {
      if (this.codeText !== "获取验证码") {
        return;
      }
      this.$refs.dataPO.validateField("phone", (errorMessage) => {
        if (!errorMessage) {
          if (!this.agreeChecked) {
            this.$message.error("请勾选用户协议");
            return;
          }
          let smPhone=doEncrypt(this.dataPO.phone)
          this.$api.user
            .getVerificationCode({ phone: smPhone })
            .then(() => {
              this.$message.success("已发送，请注意查收");
              let maxNum = 59;
              let oldCodeText = this.codeText;
              this.codeText = `${maxNum + 1}s重新发送`; //初始显示倒计时
              let codeCountDown = setInterval(() => {
                let countDownNum = maxNum--;
                this.codeText = `${countDownNum}s重新发送`;
                if (countDownNum == 0) {
                  //倒计时结束
                  this.codeText = oldCodeText;
                  clearInterval(codeCountDown);
                }
              }, 1000);
            })
            .catch((err) => {
              this.$message.error(err?.msg);
            });
        }
      });
    },
    // 点击登录
    submitForm() {
      this.$refs.dataPO.validate((valid) => {
        if (valid) {
          if (!this.agreeChecked) {
					this.$message.error("请勾选用户协议");
					return;
          }
          const loading = this.$loading({
            lock: true,
            text: "登录中...",
            spinner: "el-icon-loading",
            background: "rgba(255, 255, 255, 0.3)",
            fullscreen: false,
          });
          let params = { ...this.dataPO };
          params.deviceCode = this.$VERSION;
          if(this.bidding){
            // 绑定账号
            params.state = this.state;
            this.$api.user
              .bidding(params)
              .then((res) => {
                this.$session.setLoginToken(res.data.access_token);
                // 获取用户信息
                this.$api.personalCenter
                  .getInfo()
                  .then((user) => {
                    this.$session.setUsers(user.data);
                    this.$message.success("恭喜您，绑定成功！");
                    this.visible = false;
                  })
                  .catch((msg) => {
                    if (msg?.msg) {
                      this.$message.error(msg?.msg);
                    }
                  })
                  .finally(() => {
                    loading.close();
                  });
              })
              .catch((err) => {
                this.$message.error(err?.msg);
              })
              .finally(() => {
                loading.close();
              });
          }else{
            // 登录注册
            this.$api.user
            .doLogin(params)
            .then((res) => {
              this.$session.setLoginToken(res.data.access_token);
              // 获取用户信息
              this.$api.personalCenter
                .getInfo()
                .then((user) => {
                  this.$session.setUsers(user.data);
                  this.$message.success("恭喜您，登录成功！");
                  this.visible = false;
                })
                .catch((msg) => {
                  if (msg?.msg) {
                    this.$message.error(msg?.msg);
                  }
                })
                .finally(() => {
                  loading.close();
                });
            })
            .catch((err) => {
              this.$message.error(err?.msg);
            })
            .finally(() => {
              loading.close();
            });
          }
        } else {
          return false;
        }
      });
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.dataPO.resetFields();
    },
    // 获取用户信息
    getInfo() {
      this.$api.personalCenter
        .getInfo()
        .then((res) => {
          let user = res.data;
          user.integrity = +user.integrity;
          if (!user.imgUrl) {
            user.imgUrl = require("../../assets/img/user/user-img.png");
          }
          this.$session.setUsers(user);
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 点击协议
    openAgree() {
      this.$refs.agreementModal.visible = true;
      // this.$refs.agreementModal.init();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
